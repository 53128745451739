import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

// Style
import './ExperienciaPersonalizada.scss';
import 'react-tooltip/dist/react-tooltip.css'


// Imagens
import bgExperienciaPersonalizada from "./img/bgExperienciaPersonalizada.webp";
import imgExperienciaPersonalizada from "./img/imgExperienciaPersonalizada.webp";

function ExperienciaPersonalizada() {

    const navigate = useNavigate();

    return (
        <>
            <section className='experienciaPersonalizada'>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <span>Experiência Personalizada</span>
                            <h2>1021 Motors: Consultoria <strong>Personalizada e Inovação</strong> no Mercado Automotivo</h2>
                            <p>A 1021 Motors tem como foco oferecer uma consultoria individualizada a seus clientes, orientada na aquisição, troca e consignação, com foco na liquidez do seu automóvel novo ou usado. Sendo referência no mercado, se destacando pelo atendimento diferenciado, ambiente inovador, conquistando não só clientes, mas sim amigos.</p>
                            <a className="btn btn-primary" onClick={() => navigate(`/faleconosco`)}>entre em contato</a>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <div className='img'>
                                <div className='efeito'></div>
                                <img src={imgExperienciaPersonalizada} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    );
}

export default ExperienciaPersonalizada;
