import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Slider from "react-slick";
import api from '../../../services/apiIdz';
import { useMediaQuery } from 'react-responsive';

// Style
import './Destaques.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigationIdz } from '../../../context/useNavigation';

// Componente
import LoadingRing from '../../../components/Loading';

function Destaques() {
    const [destaques, setDestaques] = useState([]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const { idzEvent } = useNavigationIdz()

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
        dots: false,
        arrow: false,
        speed: 500,
        infinite: false ,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 1,
        infinite: isMobile ? true : false
    };


    const fetchDestaques = async () => {
        try {
            const response = await api.get('/api/destaques?status=ativo&origem=site');
            if (response.data) {
                setDestaques(response.data);
            } else {
                console.log('Nenhum destaque recebido da API.');
            }
        } catch (error) {
            console.error('Erro ao buscar os destaques:', error);
        }
    };

    useEffect(() => {
        fetchDestaques();
    }, []);


    return (
        <>
            {destaques && destaques.length > 0 ? 
                <section className='Destaques'>
                    <Container>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <h2 className='titilos'><span>nossos carros</span>destaques do mês</h2>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className='headerAction'>
                                    <a onClick={() => navigate(`/Busca/`)} className="btn btn-primary" to="/">veja todos os modelos</a>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} className='sliderDestaques'>
                                <Slider {...settings} ref={slider => { sliderRef = slider; }}>
                                    {destaques.map((destaque, index) => (
                                        <div onClick={() => { 
                                            idzEvent("paginas_acessadas", `/detalhe/${destaque.seo_url}`)
                                            navigate(`/detalhe/${destaque.seo_url}`)
                                        }} className='itemVeiculos' key={index}>
                                            <div className="foto" style={{ background: `url(${api.defaults.baseURL}${destaque.imagem})  no-repeat center` }}>

                                                    {destaque.blindado === 1 && (
                                                        <span>Blindados</span> 
                                                    )} 
                                                
                                            </div>
                                            <div className="desc">
                                                <div className='modelo'>
                                                    <h3><span>ano {destaque.ano_fabricacao}</span>{destaque.titulo}</h3>
                                                    <div className='marca'></div>
                                                </div>

                                                <div className='valor'>
                                                    <div className='valorAno'>
                                                        <span>valor</span>
                                                        <h5>{destaque.preco}</h5>
                                                        <h6>{destaque.ano_fabricacao} / {destaque.ano_modelo}</h6>
                                                    </div>
                                                    <div className="btn btn-plus"><Icon className="icons" icon="fe:plus" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </Col>
                            {destaques.length > 3 && (
                                <Col xs={12} sm={12} md={12} lg={12} className='SliderControl'>
                                    <a className='nextSlider' onClick={next}><Icon className="icons" icon="ooui:previous-rtl" /></a>
                                    <a className='prevSlider' onClick={previous}><Icon className="icons" icon="ooui:previous-ltr" /></a>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </section>
            : <LoadingRing height='630' background=''/> }
        </>

    );
}

export default Destaques;
